<template>
  <div class="menuContent flex-row">
    <!-- <div class="title flexC">新 能 源 智 能 工 业 互 联 网 平 台</div> -->
    <div class="title flexC">唐山康新新能源智能工业互联网平台</div>
    <div class="menuItems">
      <button
        class="menuItem menuSelect menuInfo"
        @click="handleCommand('/cockpit')"
      >
        驾驶舱
      </button>
      <el-dropdown
        ref="dropdown1"
        class="menuItem"
        @command="handleCommand"
        v-for="firstMenu in Menus"
      >
        <span class="menuUnSelect menuInfo flexCC"> {{ firstMenu.Name }} </span>
        <template #dropdown>
          <el-dropdown-menu
            class="dropdownMenu"
            v-if="firstMenu.Children != null && firstMenu.Children.length > 0"
          >
            <el-dropdown-item
              :command="seconedMenu.Command"
              v-for="seconedMenu in firstMenu.Children"
              ><span class="dropdownMenuItem flexCC">{{
                seconedMenu.Name
              }}</span></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <el-dropdown ref="dropdown1" class="menuItem" @command="handleCommand">
        <span class="menuUnSelect menuInfo flexCC"> 数据监控 </span>
        <template #dropdown>
          <el-dropdown-menu class="dropdownMenu">
            <el-dropdown-item command="/realTimeData"
              ><span class="dropdownMenuItem flexCC"
                >实时数据</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/historyData"
              ><span class="dropdownMenuItem flexCC"
                >历史记录</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/dataTrends"
              ><span class="dropdownMenuItem flexCC"
                >数据趋势</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/alarmTrends"
              ><span class="dropdownMenuItem flexCC"
                >报警趋势</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/alarmData"
              ><span class="dropdownMenuItem flexCC"
                >报警记录</span
              ></el-dropdown-item
            ><el-dropdown-item command="/repairData"
              ><span class="dropdownMenuItem flexCC"
                >维护记录</span
              ></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown ref="dropdown1" class="menuItem" @command="handleCommand">
        <span class="menuUnSelect menuInfo flexCC"> 组态管理 </span>
        <template #dropdown>
          <el-dropdown-menu class="dropdownMenu">
            <el-dropdown-item command="/sceneManage"
              ><span class="dropdownMenuItem flexCC"
                >场景管理</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/nodeGroupManage"
              ><span class="dropdownMenuItem flexCC"
                >分组管理</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/nodeManage"
              ><span class="dropdownMenuItem flexCC"
                >节点管理</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/bgImgManage"
              ><span class="dropdownMenuItem flexCC"
                >系统背景</span
              ></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown ref="dropdown1" class="menuItem" @command="handleCommand">
        <span class="menuUnSelect menuInfo flexCC"> 客户管理 </span>
        <template #dropdown>
          <el-dropdown-menu class="dropdownMenu">
            <el-dropdown-item command="/enterpriseCustom"
              ><span class="dropdownMenuItem flexCC"
                >企业客户</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/personalCustom"
              ><span class="dropdownMenuItem flexCC"
                >个人客户</span
              ></el-dropdown-item
            >
            <el-dropdown-item
              ><span class="dropdownMenuItem flexCC"
                >客户设备</span
              ></el-dropdown-item
            ><el-dropdown-item
              ><span class="dropdownMenuItem flexCC"
                >缴费管理</span
              ></el-dropdown-item
            >
            <el-dropdown-item
              ><span class="dropdownMenuItem flexCC"
                >欠费管理</span
              ></el-dropdown-item
            >
            <el-dropdown-item
              ><span class="dropdownMenuItem flexCC"
                >信誉管理</span
              ></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown ref="dropdown1" class="menuItem" @command="handleCommand">
        <span class="menuUnSelect menuInfo flexCC"> 设备管理 </span>
        <template #dropdown>
          <el-dropdown-menu class="dropdownMenu">
            <el-dropdown-item command="/deviceInfo"
              ><span class="dropdownMenuItem flexCC"
                >设备档案</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/deviceType"
              ><span class="dropdownMenuItem flexCC"
                >设备类型</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/deviceParame"
              ><span class="dropdownMenuItem flexCC"
                >设备参数</span
              ></el-dropdown-item
            ><el-dropdown-item
              ><span class="dropdownMenuItem flexCC"
                >远程控制</span
              ></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown ref="dropdown1" class="menuItem" @command="handleCommand">
        <span class="menuUnSelect menuInfo flexCC"> 系统管理 </span>
        <template #dropdown>
          <el-dropdown-menu class="dropdownMenu">
            <el-dropdown-item command="/userManage"
              ><span class="dropdownMenuItem flexCC"
                >用户管理</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/roleManage"
              ><span class="dropdownMenuItem flexCC"
                >角色管理</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/operationLog"
              ><span class="dropdownMenuItem flexCC"
                >操作日志</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/loginInfo"
              ><span class="dropdownMenuItem flexCC"
                >登录日志</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/parameManage"
              ><span class="dropdownMenuItem flexCC"
                >参数设置</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/EditPass"
              ><span class="dropdownMenuItem flexCC"
                >密码修改</span
              ></el-dropdown-item
            >
            <el-dropdown-item command="/"
              ><span class="dropdownMenuItem flexCC"
                >退出系统</span
              ></el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
    </div>
    <div class="rightInfo flexCB" style="padding-left: 1vw">
      <div class="flexCE item" style="width: 30%">
        <img src="../assets/img/user.png" alt="" />
        <span
          style="
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: calc(88% - 30px);
          "
          >{{ user.Name }}</span
        >
      </div>
      <div class="flexC item" style="width: 40%">
        <img src="../assets/img/position.png" alt="" />
        <div
          style="
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - 30px);
            overflow: hidden;
          "
          :title="locationDetails"
        >
          {{ location }}
        </div>
      </div>

      <div class="flexC item" style="width: 25%; height: 100%">
        <img
          title="点击查看详情"
          :src="weatherUrl"
          alt=""
          style="
            width: 70%;
            height: 70%;
            object-fit: scale-down;
            cursor: pointer;
          "
          v-if="weatherUrl != ''"
          @click="showWeatherDetails"
        />
      </div>
    </div>
    <EditDialog
      v-model="EditDialogVisivle"
      v-if="EditDialogVisivle"
      @Logout="Logout"
    ></EditDialog>
    <WeatherDialog
      v-model="WeatherDialogVisivle"
      v-if="WeatherDialogVisivle"
      :weatherDetails="weatherDetailsInfo"
      :locationDetails="locationDetails"
      :districtCode="districtCode"
      @editWeather="editWeather"
    ></WeatherDialog>
  </div>
</template>
<style lang="scss" scoped src="../assets/menu.scss"></style>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<script setup>
import { onMounted, ref, onUnmounted } from 'vue'
import router from '@/router'
import { useStore } from 'vuex'
import { isNull } from '@/utitls/validate.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import EditDialog from './components/EditDialog.vue'
import WeatherDialog from './components/weatherDialog.vue'
import { LoginOut } from '@/api/Auth'
import { GetDistrictCode, GetWeatherInfo } from '@/api/District'
const store = useStore()
const user = ref({})
const EditDialogVisivle = ref(false)
const WeatherDialogVisivle = ref(false)
const location = ref('定位获取中...')
const districtCode = ref('')
const weatherUrl = ref('')
const weatherDetailsInfo = ref({})
const locationDetails = ref('')
let weatherIntervalID = 0
const Menus = ref([])
onMounted(() => {
  try {
    new BMapGL.Geolocation().getCurrentPosition(
      (positionOptions) => {
        location.value =
          positionOptions.address.city + ' ' + positionOptions.address.district
        locationDetails.value =
          positionOptions.address.city +
          ' ' +
          positionOptions.address.district +
          ' ' +
          positionOptions.address.street
        GetDistrictCode({
          province: positionOptions.address.province,
          city: positionOptions.address.city,
          district: positionOptions.address.district
        })
          .then((res) => {
            districtCode.value = res.Data
            getWeatherInfo()
            weatherIntervalID = setInterval(() => {
              getWeatherInfo()
            }, 1000 * 60 * 60)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      (positionError) => {
        console.log(positionError.message)
      },
      { enableHighAccuracy: true }
    )
  } catch (error) {}

  if (!isNull(store.state.login.user)) {
    user.value = store.state.login.user
    Menus.value =
      store.state.login.menus == null ? [] : JSON.parse(store.state.login.menus)
  }
})
onUnmounted(() => {
  clearInterval(weatherIntervalID)
})
const handleCommand = (command) => {
  if (command == '/EditPass') {
    EditDialogVisivle.value = true
  } else if (command == '/') {
    ElMessageBox.confirm('确定要退出系统吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'info'
    }).then(() => {
      Logout()
    })
  } else router.push(command)
}
const Logout = () => {
  LoginOut({ clientType: 'PC端' })
    .then((res) => {
      ElMessage({
        message: '退出系统成功',
        type: 'success'
      })
      store.dispatch('login/userLogout')
      router.push('/')
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}
const getWeatherInfo = () => {
  if (districtCode.value != '') {
    GetWeatherInfo({ districtCode: districtCode.value })
      .then((res) => {
        weatherDetailsInfo.value = JSON.parse(res.Data).result
        weatherUrl.value =
          process.env.NODE_ENV === 'development'
            ? 'Static/weather/' + weatherDetailsInfo.value.now.text + '.png'
            : window.global_config.BASE_URL +
              ('Static/weather/' + weatherDetailsInfo.value.now.text + '.png')
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
const editWeather = (info) => {
  console.log(info)
  weatherDetailsInfo.value = info
  weatherUrl.value =
    process.env.NODE_ENV === 'development'
      ? 'Static/weather/' + weatherDetailsInfo.value.now.text + '.png'
      : window.global_config.BASE_URL +
        ('Static/weather/' + weatherDetailsInfo.value.now.text + '.png')
}
const showWeatherDetails = () => {
  WeatherDialogVisivle.value = true
}
</script>
<!-- <style lang="scss" scoped>
:deep(.el-dialog .el-dialog__header) {
  background-color: red;
}
</style> -->
