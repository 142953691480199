<template>
  <el-dialog
    :model-value="myDialogVisible"
    title="天气详情"
    width="800px"
    @opened="handleOpen"
    @close="handleClose"
    modal-class="dialogContent"
    draggable
  >
    <div
      style="width: 100%; height: 400px"
      v-loading="loading"
      element-loading-background="rgba(122, 122, 122, 0.2)"
    >
      <div class="top">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
          "
        >
          <div style="font-weight: bold">
            <el-icon><LocationInformation /></el-icon> {{ locationDetails }}
          </div>
          <div class="right">
            <el-button
              @click="RefreshData()"
              link
              style="color: white"
              title="数据刷新"
            >
              <el-icon class="el-icon--right"><Refresh /></el-icon>
            </el-button>
          </div>
        </div>
        <div class="items">
          <div class="item">环境温度：{{ weatherNow.temp }}℃</div>
          <div class="item">体感温度：{{ weatherNow.feels_like }}℃</div>
          <div class="item">环境湿度：{{ weatherNow.rh }}%</div>
        </div>
        <div class="items">
          <div class="item">天气情况：{{ weatherNow.text }}</div>
          <div class="item">
            风向风力：{{ weatherNow.wind_dir }} {{ weatherNow.wind_class }}
          </div>
          <div class="item">
            刷新时间：{{ time.substring(8, 10) }}:{{
              time.substring(10, 12)
            }}:{{ time.substring(12, 14) }}
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-carousel :interval="4000" type="card" height="260px">
          <el-carousel-item
            v-for="item in tableData"
            :key="item"
            class="flexCCC"
          >
            <div class="carItem">
              <h3 style="top: 15px; position: absolute">
                {{ item.date }} {{ item.week }}
              </h3>
              <div style="margin: 10px 0; top: 30px; position: absolute">
                {{ item.low }}℃ - {{ item.high }}℃
              </div>
              <div class="item" style="margin-top: 40px">
                日间天气：<span class="value">
                  {{ item.text_day }}
                </span>
              </div>
              <div class="item">
                夜间天气：<span class="value">
                  {{ item.text_night }}
                </span>
              </div>
              <div class="item">
                日间风力：<span class="value">
                  {{ item.wc_day }}
                </span>
              </div>
              <div class="item">
                夜间风力：<span class="value">
                  {{ item.wc_night }}
                </span>
              </div>
              <div class="item">
                日间风向：<span class="value">
                  {{ item.wd_day }}
                </span>
              </div>
              <div class="item">
                夜间风向：<span class="value">
                  {{ item.wd_night }}
                </span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { Refresh } from '@element-plus/icons-vue'
import { GetWeatherInfo } from '@/api/District'
const props = defineProps({
  weatherDetails: {
    type: Object,
    default: () => {}
  },
  locationDetails: {
    type: String,
    default: ''
  },
  districtCode: {
    type: String,
    default: ''
  }
})
const myDialogVisible = ref(false)
const tableData = ref([])
const locationDetails = ref('')
const weatherNow = ref({})
const time = ref('00000000000000')
const emits = defineEmits(['update:modelValue', 'editWeather'])
const loading = ref(false)
const handleOpen = () => {
  console.log(props.weatherDetails)
  tableData.value = props.weatherDetails.forecasts
  locationDetails.value = props.locationDetails
  weatherNow.value = props.weatherDetails.now
  console.log(weatherNow.value)
  time.value = weatherNow.value.uptime
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const RefreshData = () => {
  loading.value = true
  GetWeatherInfo({ districtCode: props.districtCode })
    .then((res) => {
      loading.value = false
      let weatherDetailsInfo = JSON.parse(res.Data).result
      tableData.value = weatherDetailsInfo.forecasts
      weatherNow.value = weatherDetailsInfo.now
      time.value = weatherNow.value.uptime
      emits('editWeather', weatherDetailsInfo)
    })
    .catch((err) => {
      loading.value = false
      console.log(err)
    })
}
</script>
<style lang="scss">
.dialogContent {
  .el-dialog {
    border: 2px solid #12d5ff;
    border-radius: 0px !important;
    .el-dialog__header {
      background: url('../../assets/img/dialog_header.jpg') no-repeat;
      background-size: 100% 100%;
      border-radius: 0px !important;
      padding-top: 10px;
    }
    .el-dialog__body {
      padding: 5px;
      background: url('../../assets/img/dialog_main.jpg') no-repeat;
      background-size: 100% 100%;
      .el-carousel {
        height: 100%;
      }
    }
  }
  .top {
    width: 100%;
    height: 28%;
    margin-bottom: 2%;
    border-bottom: 1px dashed #12d5ff;
    color: white;
    font-size: 18px;
    .items {
      display: flex;
      justify-content: space-around;
      .item {
        text-align: left;
        width: 30%;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 67%;
  }

  .el-carousel__item {
    opacity: 0.9;
    // background-color: #051832;
    color: white;
  }
  .el-carousel__item--card.is-active {
    // background-color: #041329;
    color: white;
  }
  .el-carousel__indicator.is-active button {
    background-color: #0071ff;
  }
  .el-carousel__mask {
    background-color: transparent;
  }
  .carItem {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #041329;
  }
  .item {
    width: 150px;
    text-align: left;
    margin: 5px 0;
  }
  .el-dialog__headerbtn {
    top: 0px;
  }
}
</style>
