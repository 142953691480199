import $http from '@/utitls/request'
/**
 * 获取区县代码
 */
export const GetDistrictCode = (data) => {
    return $http.get('/api/District/GetDistrictCode', { params: data })
}
/**
 * 获取区县天气情况
 */
export const GetWeatherInfo = (data) => {
    return $http.get('/api/District/GetWeatherInfo', { params: data })
}
