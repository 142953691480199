<template>
  <el-dialog
    :model-value="dialogVisible"
    title="修改密码"
    width="500px"
    @close="handleClose"
    style="border-radius: 10px"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="旧密码" prop="oldPass">
        <el-input v-model="form.oldPass" show-password />
      </el-form-item>
      <el-form-item label="新密码" prop="newPass">
        <el-input v-model="form.newPass" show-password />
      </el-form-item>
      <el-form-item label="确认密码" prop="newPass2">
        <el-input v-model="form.newPass2" show-password />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { editPass } from '@/api/Auth'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import md5 from 'blueimp-md5'

const formRef = ref(null)
var form = ref({})
const dialogVisible = ref(false)
const store = useStore()
var validateCheckPass = (rule, value, callback) => {
  if (value == '') {
    callback(new Error('请再次输入密码'))
  } else if (value != form.value.newPass) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}
const rules = reactive({
  oldPass: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }],
  newPass: [{ required: true, message: '新密码不能为空', trigger: 'blur' }],
  newPass2: [
    { required: true, message: '确认密码不能为空', trigger: 'blur' },
    { validator: validateCheckPass, trigger: 'blur' }
  ]
})

const emits = defineEmits(['update:modelValue', 'Logout'])
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      let data = {
        account: store.state.login.user.Account,
        oldPass: md5(form.value.oldPass),
        newPass: md5(form.value.newPass)
      }
      if (await editPass(data)) {
        ElMessage({
          message: '密码修改成功，请重新登录',
          type: 'success'
        })
        handleClose()
        emits('Logout')
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
